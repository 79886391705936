//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { NotificationModel } from '@/modules/notification/notification-model';
import { i18n } from '@/i18n';

const { fields } = NotificationModel;

const formSchema = new FormSchema([
  fields.id,
  fields.title,
  fields.message,
  fields.image,
  fields.name,
  fields.placeId,
]);

export default {
  name: 'app-notification-form-page',

  props: ['id'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      modelPlace:null,
      links: [],
      placeDesc:{}
    };
  },

  // mapGetters to return record , findLoading , saveLoading , placeData

  computed: {
    ...mapGetters({
      record: 'notification/form/record',
      findLoading: 'notification/form/findLoading',
      saveLoading: 'notification/form/saveLoading',
      placeData:'place/form/placeData',
    }),

    // check if the user has permission to edit or create new notification 


    isEditing() {
      return !!this.id;
    },

    fields() {
      return fields;
    },

    // this computed  for enableRtl

    isRTL() {
      return this.$rtl.isRTL;
    },
  
  },

  // check if the user want to edit or no , if he want then call doFind function to find the current user 
  // else .. call function doNew then this function will create new user 
  // initialize model with record 
  // call function doFindPlaces to set notification to that place 

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }

    this.model = formSchema.initialValues(this.record);
    this.doFindPlaces()

  },


  methods: {

      // mapAction call the  functions from store { doNew , doFind , doUpdate , doCreate  , doFindPlaces , doFindPlaceByPosition }

    ...mapActions({
      doFind: 'notification/form/doFind',
      doNew: 'notification/form/doNew',
      doUpdate: 'notification/form/doUpdate',
      doCreate: 'notification/form/doCreate',

      doFindPlaces: 'notification/form/doFindPlaces',
      doFindPlaceByPosition:'notification/form/doFindPlaceByPosition'
    }),

     // i18n for localization 

        i18n(code) {
      return i18n(code);
    },

    // Re initialize all records (reseted records )

    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

      // submitting notifications form , first check validation then catch error if exist 
      // then filter records with lat and lng 
      // call function doFindPlaceByPosition and pass filter to it as a parameter 
      // cast model if user is editing then call doUpdate function else call doCreate function 


    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      
        const filter = {
          lat:this.placeData.lat , 
          lng:this.placeData.lng
        }
       const placeDetails = await this.doFindPlaceByPosition(filter)

      this.model.placeId = placeDetails[0].id;
      const { id, ...values } = formSchema.cast(this.model);

      if (this.isEditing) {
        return this.doUpdate({
          id,
          values,
        });
      } else {
        return this.doCreate(values);
      }
    },
  },
};
